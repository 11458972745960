


























































































































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'

@Observer
@Component({
  components: {
    draggable,
  },
})
export default class PollListDraggable extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  postDialogController
}
